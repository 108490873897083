
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: Poppins, sans-serif;
  /* font-family: "Montserrat", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  /* display:initial; */
  width: 100%;
  /* @media (max-width:991px){
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    width: 100%;
    
  } */
  
}
html {
  display: initial; /* Resetting display property to its initial value */
}


/* code {
  font-family: "Montserrat", sans-serif;
} */
